import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import PushToStart from '../images/pushToStart.png'
import TeamDash from '../images/teamDash.svg'
import SecurityShield from '../images/securityShield.svg'

export default class SalesforceIntegration extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  render() {
    return (
      <Default>
      <Helmet>
        <title>Truly | The Most Complete CRM Integration</title>
      </Helmet>

      <WrapperBackground
        color={colors.trulyDark}
        background='businessScene4'
      >
        <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
          <Box width="1">
            <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            OWN YOUR DATA
            </Title3>
            <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            YOUR CRM IS THE HEART OF YOUR ORGANIZATION. DON'T FEED IT BAD DATA.
            </PageTitle>
          </Box>
        </Section>
      </WrapperBackground>

      <WrapperSolid>
        <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
          <Box width={[1/2, 1/2]} pb="2rem">
            <WidthImg src={PushToStart} alt="CTI"/>
          </Box>
          <Box width={[1, 1/2]}>
            <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
            REIMAGINED COMPUTER-TELEPHONY INTEGRATION
            </Title3>
            <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Truly's CTI allows computers and phones to finally talk to each other. Maximize clean data capture with the strongest CRM integration. Leverage your call data to make sales teams more efficient and effective.
            </Large>
          </Box>
        </Section>
      </WrapperSolid>

      <WrapperBackground
        color={colors.trulyDark}
        background='headsetPerson1'
      >
        <Section flexDirection="column" py={['6rem', '7rem']} background={colors.trulyDark}>
          <Box width={[1, 3/4]} mb="2rem">
            <CenteredImg src={TeamDash} alt="Team Dashboard"/>
          </Box>
          <Box width={[1, 1/2]}>
            <Title3 center as="h2" color={colors.accentFog} bold mb="1.5rem">
            SETTING NEW BENCHMARKS
            </Title3>
            <Large center as="p" color={colors.white} mb="1.5rem">
              Through deep integrations into every device, Truly automatically captures every call and SMS interaction, while enriching the activity with contextual data that helps you better identify the behaviors that drive success.
            </Large>
          </Box>
        </Section>
      </WrapperBackground>

      <WrapperBackground
        color={colors.trulyDark}
        background='serverRoom'
        gradientType='light'
      >
        <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
          <Box width={[1, 1/2]} pr={['0pt', '32pt']}>
            <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
            SUPERIOR SAFETY & CONTROL
            </Title3>
            <Large as="p" color={colors.trulyDark} mb="1.5rem">
              Detailed logs show your Sales Operations and IT teams what is actually happening with your sales data. With no managed package or CTI requirement, Truly can be deployed at any scale or pace that works for your business.
            </Large>
          </Box>
          <Box width="1/2">
            <CenteredImg src={SecurityShield} alt="Security"/>
          </Box>
        </Section>
      </WrapperBackground>

      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
